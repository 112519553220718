/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "../../../css/promenade-mobile.css";
import "../../../css/promenade.css";
import { useSwipeable } from "react-swipeable";
import { Modal, message } from "antd";
import { Button, Form, Input, Radio } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";

import section1Bg from "../../../images/commercial/ongoing/promenade/section-1-bg-min.jpg";
import section1Asset2 from "../../../images/commercial/ongoing/promenade/Asset-2.png";
import section1Asset3 from "../../../images/commercial/ongoing/promenade/Asset-3.png";
import section2Img2 from "../../../images/commercial/ongoing/promenade/Asset-31.png";
import section2Img22 from "../../../images/commercial/ongoing/promenade/Asset-32.png";

import section2Bg from "../../../images/commercial/ongoing/promenade/section-2-bg-min.jpg";
import logo from "../../../images/commercial/ongoing/promenade/bashyam-logo.png";
import logo2 from "../../../images/commercial/ongoing/promenade/Promenade - Revised.png";
import icon1 from "../../../images/commercial/ongoing/promenade/icon-2.png";
import promenadeImg from "../../../images/commercial/ongoing/promenade/Promenade - Revised.png";
import section3Bg from "../../../images/commercial/ongoing/promenade/section-3-bg.png";

import extImg1 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-02.jpg";
import extImg2 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-04.jpg";
import extImg3 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-19.jpg";
import extImg4 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-22.jpg";

import intImg1 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-06-min.jpg";
import intImg2 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-07.jpg";
import intImg3 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-08.jpg";
import intImg4 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-09-min.jpg";
import intImg5 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-12.jpg";

import juneImg1 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/1.jpg";
import juneImg2 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/2.jpg";
import juneImg3 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/3.jpg";
import juneImg4 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/4.jpg";
import juneImg5 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/5.jpg";
import juneImg6 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/6.jpg";

import julyImg1 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/1.jpg";
import julyImg2 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/2.jpg";
import julyImg3 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/3.jpg";
import julyImg4 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/4.jpg";
import julyImg5 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/5.jpg";

import AugustImg1 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/1.jpg";
import AugustImg2 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/2.jpg";
import AugustImg3 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/3.jpg";
import AugustImg4 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/4.jpg";

import septImg1 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/1.jpg";
import septImg2 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/2.jpg";
import septImg3 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/3.jpg";
import septImg4 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/4.jpg";

import october2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/1.jpg";
import october2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/2.jpg";
import october2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/3.jpg";
import october2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/4.jpg";

import november2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/1.jpg";
import november2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/2.jpg";
import november2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/3.jpg";
import november2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/4.jpg";

import december2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/1.jpg";
import december2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/2.jpg";
import december2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/3.jpg";
import december2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/4.jpg";
import december2024Img5 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/5.jpg";
import december2024Img6 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/6.jpg";

import january2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/january-2025/1.jpg";
import january2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/january-2025/2.jpg";
import january2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/january-2025/3.jpg";
import january2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/january-2025/4.jpg";
import january2024Img5 from "../../../images/commercial/ongoing/promenade/project-status/january-2025/5.jpg";
import january2024Thumbnail from "../../../images/commercial/ongoing/promenade/project-status/january-2025/video_thumbnail.png";

import section4Bg from "../../../images/commercial/ongoing/promenade/section-4-bg.png";
import section5Bg from "../../../images/commercial/ongoing/promenade/section-5-bg.png";

import section6Bg from "../../../images/commercial/ongoing/promenade/section-6-img.png";
import gsap from "gsap";

import section6Arrow from "../../../images/commercial/ongoing/promenade/get-in-touch-arrow.png";

import miniIcon from "../../../images/commercial/ongoing/promenade/icon-2.png";
import miniIcon2 from "../../../images/commercial/ongoing/promenade/icon-1.png";
import TextArea from "antd/es/input/TextArea";
import Footer from "../../common/Footer";

function PromenadeMobile({ deviceHeight, deviceType }) {
  let currentSection = useRef(0);
  let sections = useRef(null);
  let totalSections = useRef(0);
  let scrolledUp = useRef(false);
  let scrolledDown = useRef(false);
  let isSynopsisScrolled = useRef(false);

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  const [tabletMode, setTabletMode] = useState(false);
  const tabletModeRef = useRef(false);

  const [swiped, setSwiped] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showHeader, setShowHeader] = useState(false);
  let headerRef = useRef(null);

  let section2ScrollIndex = useRef(0);
  let section2ScrollTimer = useRef(null);
  let section2ScrollDiv = useRef(null);

  let section3SelectedIndex = useRef(0);
  const [section3CurrentIndex, setSection3CurrentIndex] = useState(0);

  let section3BoxRef = useRef(null);

  const [section4SelectedTabIndex, setSection4SelectedTabIndex] = useState(0);
  const [section5SelectedTabIndex, setSection5SelectedTabIndex] = useState(8);

  let isSection4Animated = useRef(false);
  let section4Ref1 = useRef(null);

  let section4BgRef = useRef(null);

  const [showSection4Bg, setShowSection4Bg] = useState(false);

  const [selected4Image, setSelected4Image] = useState(null);
  const [total4Image, setTotal4Image] = useState(null);
  const [showLeft4Arrow, setShowLeft4Arrow] = useState(false);
  const [showRight4Arrow, setShowRight4Arrow] = useState(false);

  const [selected5Image, setSelected5Image] = useState(null);
  const [total5Image, setTotal5Image] = useState(null);
  const [showLeft5Arrow, setShowLeft5Arrow] = useState(false);
  const [showRight5Arrow, setShowRight5Arrow] = useState(false);
  const [clickedImage, setClickedImage] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  let sliderModalRef = useRef(0);

  let isSection5Animated = useRef(false);
  let section5Ref1 = useRef(null);

  let isSection6Animated = useRef(false);
  let section6Ref1 = useRef(null);

  let isSection7Animated = useRef(false);
  let section7Ref1 = useRef(null);

  let section8Ref1 = useRef(null);

  let selectionBarRef = useRef(null);
  let menuItem1Ref = useRef(null);
  let menuItem2Ref = useRef(null);
  let menuItem3Ref = useRef(null);
  let menuItem4Ref = useRef(null);
  let menuItem5Ref = useRef(null);
  let menuItem6Ref = useRef(null);
  let menuItem7Ref = useRef(null);

  let movement = useRef(0);
  let lastTouchPos = useRef(null);
  let currentTouchPos = useRef(null);

  let movementX = useRef(0);
  let lastTouchPosX = useRef(null);
  let currentTouchPosX = useRef(null);

  let imageSwiped = useRef(false);

  let synopsisTextRef = useRef(null);

  let carousel1Ref1 = useRef(null);
  let carousel1Ref2 = useRef(null);
  let carousel2Ref1 = useRef(null);

  const [formName, setFormName] = useState("");
  const [formContact, setFormContact] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const [showRequiredError, setShowRequiredError] = useState([0, 0, 0, 0]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const projectSynopsisWeb = (
    <div className="tablet-mode">
      <div className="overlay" />
      <img alt="" src={section2Bg} className="bg-img" />
      <img alt="" src={section2Img2} className="bg-img-2" />

      <div className="layer-1">
        <div className="text-container">
          <img alt="" src={promenadeImg} className="asset-1" />
          <div className="scroll-container-synopsis" ref={section2ScrollDiv}>
            <p style={{ paddingRight: 30 }}>
              <div
                className="text-container-heading"
                style={{ fontWeight: 600 }}
              >
                Modern, Iconic, Sustainable Commercial Marvel
              </div>
              <div>
                Baashyaam’s Promenade in Guindy is an Iconic, Modern Commercial
                Marvel, that looms spectacularly large right on the spine of
                Anna Salai.
              </div>
              <div>
                Refined aesthetic detailing is omnipresent throughout the
                construction. This strategically located corner site is flanked
                on either side by approach roads (in the South East and South
                directions).
              </div>
              <div>
                Contemporary and innovative, it effortlessly embraces design
                thinking, raw materials and technology. The strong emphasis is
                on unprecedented scale, grandeur and sustainability.
              </div>
            </p>
            <p>
              <div
                className="text-container-heading"
                style={{ fontWeight: 600 }}
              >
                Impressive Specs
              </div>
              <div>Developed on a 5.6 acre land parcel</div>
              <div>A standalone, 63.5m tall towering structure</div>
              <div>1.81 Million Sq.ft. Built-up Area</div>
              <div>
                11 office floors, designed for premium Commercial/IT offices
              </div>
              <div>
                A large atrium space to ensure internal vertical connectivity
                between office floors
              </div>
              <div>5 levels of parking</div>
              <div>
                Ground floor dedicated to retail, bespoke services and an
                automobile showroom.
              </div>
            </p>

            <p>
              <div
                className="text-container-heading"
                style={{ fontWeight: 600 }}
              >
                U.S. Green Building Council and Green Business Certification
                Inc. has conferred Baashyaam Promenade with Platinum
                Pre-certification Rating under the LEED v4 Building Design and
                Construction (Core and Shell Development Rating System)
              </div>
              <div>
                This acknowledges the commitment, efforts and certifies that all
                the necessary pre-requisite Leadership steps have been taken
                towards constructing a Leed-Certified, High-Performance, Green
                Building.
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const projectSynopsisMobile = (
    <div className="mobile-mode">
      <div className="overlay" />

      <div className="layer-1">
        <div className="text-container">
          <img alt="" src={promenadeImg} className="asset-1" />
          <div
            className="text-container-div"
            ref={synopsisTextRef}
            onScroll={(e) => e.stopPropagation()}
          >
            <p style={{ paddingRight: 30 }}>
              <div
                className="text-container-heading"
                style={{ fontWeight: 600 }}
              >
                Modern, Iconic, Sustainable Commercial Marvel
              </div>
              <div>
                Baashyaam’s Promenade in Guindy is an Iconic, Modern Commercial
                Marvel, that looms spectacularly large right on the spine of
                Anna Salai.
              </div>
              <div>
                Refined aesthetic detailing is omnipresent throughout the
                construction. This strategically located corner site is flanked
                on either side by approach roads (in the South East and South
                directions).
              </div>
              <div>
                Contemporary and innovative, it effortlessly embraces design
                thinking, raw materials and technology. The strong emphasis is
                on unprecedented scale, grandeur and sustainability.
              </div>
            </p>
            <p>
              <div
                className="text-container-heading"
                style={{ fontWeight: 600 }}
              >
                Impressive Specs
              </div>
              <div>Developed on a 5.6 acre land parcel</div>
              <div>A standalone, 63.5m tall towering structure</div>
              <div>1.81 Million Sq.ft. Built-up Area</div>
              <div>
                11 office floors, designed for premium Commercial/IT offices
              </div>
              <div>
                A large atrium space to ensure internal vertical connectivity
                between office floors
              </div>
              <div>5 levels of parking</div>
              <div>
                Ground floor dedicated to retail, bespoke services and an
                automobile showroom.
              </div>
            </p>

            <p>
              <div
                className="text-container-heading"
                style={{ fontWeight: 600 }}
              >
                U.S. Green Building Council and Green Business Certification
                Inc. has conferred Baashyaam Promenade with Platinum
                Pre-certification Rating under the LEED v4 Building Design and
                Construction (Core and Shell Development Rating System)
              </div>
              <div>
                This acknowledges the commitment, efforts and certifies that all
                the necessary pre-requisite Leadership steps have been taken
                towards constructing a Leed-Certified, High-Performance, Green
                Building.
              </div>
            </p>
          </div>
        </div>
        <img alt="" src={section2Img22} className="bg-img-2" />

        <img alt="" src={section2Bg} className="bg-img" />
      </div>
    </div>
  );

  useEffect(() => {
    let newArr = [...showRequiredError];
    if (formName) {
      newArr[0] = false;
    }
    if (formContact) {
      newArr[1] = false;
    }
    if (formEmail) {
      newArr[2] = false;
    }
    if (formMessage) {
      newArr[3] = false;
    }
    setShowRequiredError(newArr);
  }, [formName, formContact, formEmail, formMessage]);

  function setDevice() {
    currentSection.current = 0;
    scrollToSection(currentSection.current);
    scrollFn();
    if (window.innerWidth >= 768) {
      setTabletMode(true);
      tabletModeRef.current = true;
    } else {
      setTabletMode(false);
      tabletModeRef.current = false;
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("orientationchange", setDevice);
      window.addEventListener("resize", setDevice);
      setDevice();
    }

    const pageContainer = document.querySelector(".page-container");

    if (pageContainer) {
      pageContainer.classList.add("no-padd");
    }

    return () => {
      const whatsappBtn = document.querySelector("#whatsappbutton");
      const mobilelivesquare = document.querySelector(".mobilelivesquare");
      const pageContainer = document.querySelector(".page-container");

      if (whatsappBtn) {
        whatsappBtn.classList.remove("hide");
      }
      if (mobilelivesquare) {
        mobilelivesquare.classList.remove("hide");
      }
      if (pageContainer) {
        pageContainer.classList.remove("no-padd");
      }
    };
  }, []);

  useEffect(() => {
    if (clickedImage && (selected4Image || selected5Image)) clickedImageFn();
  }, [clickedImage, selected4Image, selected5Image]);

  function clickedImageFn() {
    if (selected4Image && total4Image) {
      slideToImage(4);
      setImageModal(true);
      setTimeout(() => {
        setClickedImage(false);
      }, 2000);
    }
    if (selected5Image && total5Image) {
      slideToImage(5);
      setImageModal(true);
      setTimeout(() => {
        setClickedImage(false);
      }, 2000);
    }
  }

  useEffect(() => {
    const carousel4Length = document.querySelectorAll(
      `.mobile-version #section-3 .image-row .image-container`
    );
    const carousel5Length = document.querySelectorAll(
      `.mobile-version #section-4 .image-row .image-container`
    );
    const imageRow = document.querySelectorAll(
      ".mobile-version .section-4-container .layer-1 .image-row"
    );

    if (carousel4Length.length) {
      setTotal4Image(carousel4Length.length);
      setTotal5Image(carousel5Length.length);
    }

    if (imageRow.length) {
      imageRow.forEach((row) => {
        if (row.scrollWidth > row.clientWidth) {
          row.style.justifyContent = "unset";
        }
      });
    }
  }, [selected4Image, selected5Image]);

  useEffect(() => {
    if (swiped) {
      const btnContainer = document.querySelector(
        ".mobile-version .section-7-container .layer-1 .image-container .btn-container"
      );
      const arrowContainer = document.querySelector(
        ".mobile-version .section-7-container .layer-1 .image-container .btn-container .arrow-container"
      );
      if (arrowContainer && btnContainer) {
        arrowContainer.style.transform = `translateX(${btnContainer.clientWidth -
          arrowContainer.clientWidth}px)`;
      }
      setShowModal(true);
      setTimeout(() => {
        setSwiped(false);
        if (arrowContainer && btnContainer) {
          arrowContainer.style.transform = `translateX(0px)`;
        }
      }, 3000);
    }
  }, [swiped]);

  useEffect(() => {
    sections.current = document.querySelectorAll(".fade-in-section");
    totalSections.current = sections.current.length;

    document
      .querySelector(".scroll-container")
      .addEventListener("touchstart", function(event) {
        const touch = event.touches[0];
        lastTouchPos.current = touch.clientY;
        lastTouchPosX.current = touch.clientX;
        scrolledUp.current = false;
        scrolledDown.current = false;
      });

    document
      .querySelector(".scroll-container")
      .addEventListener("touchend", function(event) {
        lastTouchPos.current = null;
        lastTouchPosX.current = null;
        scrolledUp.current = true;
        scrolledDown.current = true;
      });

    document.querySelector(".scroll-container").addEventListener(
      "touchmove",
      (event) => {
        if (
          carousel1Ref1.current &&
          carousel1Ref2.current &&
          carousel2Ref1.current &&
          synopsisTextRef.current &&
          !carousel1Ref1.current.contains(event.target) &&
          !carousel1Ref1.current.contains(event.target) &&
          !carousel2Ref1.current.contains(event.target) &&
          !synopsisTextRef.current.contains(event.target)
        )
          event.preventDefault();

        const gallerySection = document.querySelector(
          ".mobile-version .section-4-container .layer-1 .image-row.row-1 .image-container"
        );

        const touch = event.touches[0];
        currentTouchPos.current = touch.clientY;
        currentTouchPosX.current = touch.clientX;

        if (lastTouchPos.current) {
          movement.current = currentTouchPos.current - lastTouchPos.current;
        }

        if (lastTouchPosX.current) {
          movementX.current = currentTouchPosX.current - lastTouchPosX.current;
        }

        if (
          Math.abs(movement.current) > Math.abs(movementX.current) &&
          !gallerySection.contains(event.target) &&
          (tabletModeRef.current
            ? true
            : synopsisTextRef.current &&
              !synopsisTextRef.current.contains(event.target))
        ) {
          if (movement.current < 0) {
            if (!scrolledUp.current) {
              scrolledUp.current = true;
              scrolledDown.current = false;

              if (
                currentSection.current === 2 &&
                section3SelectedIndex.current !== 2
              ) {
                section3SelectedIndex.current++;
                setSection3CurrentIndex(section3SelectedIndex.current);
              } else {
                if (currentSection.current < totalSections.current - 1) {
                  currentSection.current++;
                }
              }
            }
          } else if (movement.current > 0) {
            if (!scrolledDown.current) {
              scrolledDown.current = true;
              scrolledUp.current = false;

              if (
                currentSection.current === 2 &&
                section3SelectedIndex.current !== 0
              ) {
                section3SelectedIndex.current--;
                setSection3CurrentIndex(section3SelectedIndex.current);
              } else {
                if (currentSection.current > 0) {
                  currentSection.current--;
                }
              }
            }
          }
        }

        scrollFn();
      },
      { passive: false }
    );

    setTimeout(() => {
      setShowHeader(true);
    }, 1500);

    if (section4BgRef.current) {
      section4BgRef.current.style.top = `${document.querySelector(
        "#section-3 .layer-1-container"
      ).offsetTop + 20}px`;
    }

    if (selectionBarRef.current && menuItem1Ref.current) {
      selectionBarRef.current.style.left = `${menuItem1Ref.current.getBoundingClientRect()
        .x - 5}px`;
      selectionBarRef.current.style.width = `${menuItem1Ref.current.getBoundingClientRect()
        .width + 10}px`;
    }

    scrollToSection(currentSection.current);
  }, [section3CurrentIndex]);

  function slideToImage(index) {
    setTimeout(() => {
      const carouselItems = document.querySelectorAll(
        `#carousel-container-${index} .carousel_item`
      );
      if (carouselItems.length) {
        sliderModalRef.current =
          sliderModalRef.current -
          100 * ((selected4Image || selected5Image) - 1);
        carouselItems.forEach((item, index) => {
          item.style.transform = `translateX(${sliderModalRef.current}%)`;
        });
      }
    }, 100);
  }

  function scrollToSection(index) {
    if (typeof window !== "undefined") {
      if (index === 1 && selectionBarRef.current && menuItem1Ref.current) {
        selectionBarRef.current.style.opacity = `1`;
        selectionBarRef.current.style.left = `${menuItem1Ref.current.getBoundingClientRect()
          .x - 5}px`;
        selectionBarRef.current.style.width = `${menuItem1Ref.current.getBoundingClientRect()
          .width + 10}px`;
      } else if (
        index === 2 &&
        selectionBarRef.current &&
        menuItem2Ref.current
      ) {
        if (section3CurrentIndex === 0) {
          if (section3BoxRef.current) {
            section3BoxRef.current.style.transform = `translateX(${0}px)`;
          }
          selectionBarRef.current.style.opacity = `1`;
          selectionBarRef.current.style.left = `${menuItem2Ref.current.getBoundingClientRect()
            .x - 5}px`;
          selectionBarRef.current.style.width = `${menuItem2Ref.current.getBoundingClientRect()
            .width + 10}px`;
        } else if (section3CurrentIndex === 1) {
          if (section3BoxRef.current) {
            section3BoxRef.current.style.transform = `translateX(${-window.innerWidth}px)`;
          }
          selectionBarRef.current.style.opacity = `1`;
          selectionBarRef.current.style.left = `${menuItem3Ref.current.getBoundingClientRect()
            .x - 5}px`;
          selectionBarRef.current.style.width = `${menuItem3Ref.current.getBoundingClientRect()
            .width + 10}px`;
        } else if (section3CurrentIndex === 2) {
          if (section3BoxRef.current) {
            section3BoxRef.current.style.transform = `translateX(${-2 *
              window.innerWidth}px)`;
          }
          selectionBarRef.current.style.opacity = `1`;
          selectionBarRef.current.style.left = `${menuItem4Ref.current.getBoundingClientRect()
            .x - 5}px`;
          selectionBarRef.current.style.width = `${menuItem4Ref.current.getBoundingClientRect()
            .width + 10}px`;
        }
      } else if (
        index === 3 &&
        selectionBarRef.current &&
        menuItem5Ref.current
      ) {
        selectionBarRef.current.style.opacity = `1`;
        selectionBarRef.current.style.left = `${menuItem5Ref.current.getBoundingClientRect()
          .x - 5}px`;
        selectionBarRef.current.style.width = `${menuItem5Ref.current.getBoundingClientRect()
          .width + 10}px`;
      } else if (
        index === 4 &&
        selectionBarRef.current &&
        menuItem6Ref.current
      ) {
        selectionBarRef.current.style.opacity = `1`;
        selectionBarRef.current.style.left = `${menuItem6Ref.current.getBoundingClientRect()
          .x - 5}px`;
        selectionBarRef.current.style.width = `${menuItem6Ref.current.getBoundingClientRect()
          .width + 10}px`;
      } else if (
        index === 5 &&
        selectionBarRef.current &&
        menuItem7Ref.current
      ) {
        selectionBarRef.current.style.opacity = `1`;
        selectionBarRef.current.style.left = `${menuItem7Ref.current.getBoundingClientRect()
          .x - 5}px`;
        selectionBarRef.current.style.width = `${menuItem7Ref.current.getBoundingClientRect()
          .width + 10}px`;
      } else {
        if (selectionBarRef.current) {
          selectionBarRef.current.style.opacity = `0`;
        }
      }

      sections.current &&
        sections.current.length &&
        sections.current.forEach((section, i) => {
          document.querySelector(`#section-${index}`).classList.add("show");

          if (currentSection.current === 3) {
            document.querySelector(`#section-4`).classList.remove("show");
          }
          if (currentSection.current === 4) {
            document.querySelector(`#section-3`).classList.remove("show");
            document.querySelector(`#section-5`).classList.remove("show");
            document.querySelector(`#section-6`).classList.remove("show");
          }
          if (currentSection.current === 5) {
            document.querySelector(`#section-5`).classList.add("show");
            document.querySelector(`#section-6`).classList.add("show");
            document.querySelector(`#section-4`).classList.remove("show");
          }

          document.querySelector(`#section-${index}`).scrollIntoView({
            behavior: "smooth",
            block: index === 4 || index === 5 ? "center" : "start",
          });
        });
    }
  }

  const handlers = useSwipeable({
    onSwipeStart: () => setSwiped(true),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  function slideImageStart(event, index) {
    const touch = event.touches[0];
    lastTouchPos.current = touch.clientY;
    lastTouchPosX.current = touch.clientX;
  }

  function slideImageMove(event, index) {
    const touch = event.touches[0];
    currentTouchPos.current = touch.clientY;
    currentTouchPosX.current = touch.clientX;

    if (lastTouchPos.current) {
      movement.current = currentTouchPos.current - lastTouchPos.current;
    }

    if (lastTouchPosX.current) {
      movementX.current = currentTouchPosX.current - lastTouchPosX.current;
    }

    if (Math.abs(movement.current) < Math.abs(movementX.current)) {
      if (movementX.current < 0) {
        // swipe right
        slideRight(index);
      } else {
        // swipe left
        slideLeft(index);
      }
    }
  }

  function slideLeft(index) {
    setClickedImage(false);
    const carouselItems = document.querySelectorAll(
      `#carousel-container-${index} .carousel_item`
    );
    if (carouselItems.length) {
      if (
        selected4Image &&
        total4Image &&
        Math.abs(selected4Image) > 1 &&
        !imageSwiped.current
      ) {
        imageSwiped.current = true;
        sliderModalRef.current = sliderModalRef.current + 100;
        carouselItems.forEach((item, index) => {
          item.style.transform = `translateX(${sliderModalRef.current}%)`;
        });
        if (index === 4) {
          setSelected4Image((prevIndex) => prevIndex - 1);
        }
      }

      if (
        selected5Image &&
        total5Image &&
        Math.abs(selected5Image) > 1 &&
        !imageSwiped.current
      ) {
        imageSwiped.current = true;
        sliderModalRef.current = sliderModalRef.current + 100;
        carouselItems.forEach((item, index) => {
          item.style.transform = `translateX(${sliderModalRef.current}%)`;
        });
        if (index === 5) setSelected5Image((prevIndex) => prevIndex - 1);
      }
    }
  }

  function slideRight(index) {
    setClickedImage(false);
    const carouselItems = document.querySelectorAll(
      `#carousel-container-${index} .carousel_item`
    );
    if (carouselItems.length) {
      if (
        selected4Image &&
        total4Image &&
        Math.abs(selected4Image) < Math.abs(total4Image) &&
        !imageSwiped.current
      ) {
        imageSwiped.current = true;
        sliderModalRef.current = sliderModalRef.current - 100;
        carouselItems.forEach((item, index) => {
          item.style.transform = `translateX(${sliderModalRef.current}%)`;
        });
        if (index === 4) {
          setSelected4Image((prevIndex) => prevIndex + 1);
        }
      }

      if (
        selected5Image &&
        total5Image &&
        Math.abs(selected5Image) < Math.abs(total5Image) &&
        !imageSwiped.current
      ) {
        imageSwiped.current = true;
        sliderModalRef.current = sliderModalRef.current - 100;
        carouselItems.forEach((item, index) => {
          item.style.transform = `translateX(${sliderModalRef.current}%)`;
        });
        if (index === 5) setSelected5Image((prevIndex) => prevIndex + 1);
      }
    }
  }

  function scrollSynopsis() {
    clearTimeout(section2ScrollTimer.current);
    section2ScrollTimer.current = setTimeout(() => {
      if (section2ScrollDiv.current) {
        section2ScrollIndex.current = section2ScrollIndex.current + 1;
        if (section2ScrollIndex.current === 3) section2ScrollIndex.current = 0;

        section2ScrollDiv.current.style.transform = `translateX(${section2ScrollIndex.current *
          100 *
          -1}%)`;
      }
      scrollSynopsis();
    }, 3000);
  }

  function scrollFn() {
    if (tabletModeRef.current) {
      if (currentSection.current === 1) {
        scrollSynopsis();
      } else {
        clearTimeout(section2ScrollTimer.current);
      }

      if (currentSection.current >= 3) {
        if (headerRef.current) {
          headerRef.current.classList.add("dark-mode");
        }
      } else {
        if (headerRef.current) {
          headerRef.current.classList.remove("dark-mode");
        }
      }
    } else {
      if (currentSection.current === 1 && !tabletModeRef.current) {
        if (headerRef.current) {
          headerRef.current.classList.add("dark-mode");
        }
      }
      if (currentSection.current >= 3) {
        if (headerRef.current) {
          headerRef.current.classList.add("dark-mode");
        }
      } else {
        if (headerRef.current) {
          headerRef.current.classList.remove("dark-mode");
        }
      }
    }

    if (currentSection.current === 3) {
      if (!isSection4Animated.current) {
        isSection4Animated.current = true;
        if (section4Ref1.current) {
          gsap.to(".section-4-container .layer-1 .tab-menu", {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: "power1.inOut",
            delay: 0.2,
          });
          gsap.to(".section-4-container .layer-1 .main-content .row-1", {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: "power1.inOut",
            delay: 0.8,
          });
          gsap.to(".section-4-container .layer-1 .main-content .row-2", {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: "power1.inOut",
            delay: 1.5,
          });
        }
      }
    }

    if (currentSection.current === 3) {
      if (!isSection6Animated.current) {
        isSection6Animated.current = true;
        if (section6Ref1.current) {
          gsap.to(".section-6-container .layer-1 .image-container", {
            y: 0,
            duration: 0.6,
            delay: 1.8,
            ease: "power1.inOut",
          });
        }
      }
    }

    if (currentSection.current === 3) {
      setShowSection4Bg(true);
      section4BgRef.current.style.transform = `translateY(${0}px)`;
    } else if (currentSection.current === 4) {
      section4BgRef.current.style.transform = `translateY(${-(
        section4BgRef.current.offsetHeight +
        section4BgRef.current.offsetTop -
        (document.querySelector("#section-3").offsetTop +
          document.querySelector("#section-3").offsetHeight)
      )}px)`;
    }

    if (currentSection.current === 4) {
      if (!isSection4Animated.current) {
        isSection4Animated.current = true;
        if (section4Ref1.current) {
          gsap.to(".section-4-container .layer-1 .tab-menu", {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: "power1.inOut",
            delay: 0.2,
          });
          gsap.to(".section-4-container .layer-1 .main-content .row-1", {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: "power1.inOut",
            delay: 0.8,
          });
          gsap.to(".section-4-container .layer-1 .main-content .row-2", {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: "power1.inOut",
            delay: 1.5,
          });
        }
      }
      if (!isSection6Animated.current) {
        isSection6Animated.current = true;
        if (section6Ref1.current) {
          gsap.to(".section-6-container .layer-1 .image-container", {
            y: 0,
            duration: 0.8,
            delay: 1,
            ease: "power1.inOut",
          });
        }
      }
    }

    if (currentSection.current === 5) {
      if (!isSection4Animated.current) {
        isSection4Animated.current = true;
        if (section4Ref1.current) {
          gsap.to(".section-4-container .layer-1 .tab-menu", {
            opacity: 1,
            y: 0,
            duration: 0,
            ease: "power1.inOut",
            delay: 0,
          });
          gsap.to(".section-4-container .layer-1 .main-content .row-1", {
            opacity: 1,
            y: 0,
            duration: 0,
            ease: "power1.inOut",
            delay: 0.1,
          });
          gsap.to(".section-4-container .layer-1 .main-content .row-2", {
            opacity: 1,
            y: 0,
            duration: 0,
            ease: "power1.inOut",
            delay: 0.2,
          });
        }
      }
      if (!isSection6Animated.current) {
        if (section6Ref1.current) {
          gsap.to(".section-6-container .layer-1 .image-container", {
            y: 0,
            duration: 0.6,
            delay: 0.4,
            ease: "power1.inOut",
          });
        }
      }
      if (!isSection7Animated.current) {
        if (section7Ref1.current) {
          gsap.to(".section-7-container .layer-1 .image-container", {
            opacity: 1,
            x: 0,
            duration: 0.8,
            delay: isSection6Animated.current ? 0.4 : 0.9,
            ease: "power1.inOut",
          });
        }
      }
      isSection6Animated.current = true;
      isSection7Animated.current = true;
    }

    scrollToSection(currentSection.current);
  }

  function clearFormData() {
    setFormName("");
    setFormContact("");
    setFormEmail("");
    setFormMessage("");
    setShowRequiredError([0, 0, 0, 0]);
  }

  async function submitFormData() {
    console.log(showRequiredError);
    let newArr = [...showRequiredError];
    if (!formName) {
      newArr[0] = true;
    } else {
      newArr[0] = false;
    }

    if (!formContact) {
      newArr[1] = true;
    } else {
      newArr[1] = false;
    }

    if (!formEmail) {
      newArr[2] = true;
    } else {
      newArr[2] = false;
    }

    if (!formMessage) {
      newArr[3] = true;
    } else {
      newArr[3] = false;
    }
    setShowRequiredError(newArr);

    if (!formName || !formContact || !formEmail || !formMessage) {
      return;
    }

    try {
      let response = await axios.post(
        "https://emailer.billiontags.com/bashyam/",
        {
          email: formEmail,
          name: formName,
          number: formContact,
          message: formMessage,
          type: "Enquiry",
        }
      );

      if (response.status === 200) {
        clearFormData();
        setShowModal(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 4000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div class="scroll-container mobile-version">
        <div class="content">
          <div
            ref={headerRef}
            className={`header-container ${showHeader ? "" : "hide"}`}
          >
            {/* <span className="bar">
              <span className="line line-1"></span>
              <span className="line line-2"></span>
              <span className="line line-3"></span>
            </span> */}
            <span className="selection-bar" ref={selectionBarRef}></span>
            <span
              className="menu-item"
              ref={menuItem1Ref}
              onClick={() => {
                scrollToSection(1);
                currentSection.current = 1;
                scrollFn();
              }}
            >
              Project Synopsis
            </span>
            <span
              className="menu-item"
              ref={menuItem2Ref}
              onClick={() => {
                scrollToSection(2);
                currentSection.current = 2;
                setSection3CurrentIndex(0);
                scrollFn();
              }}
            >
              Project Specifications
            </span>
            <span
              className="menu-item"
              ref={menuItem3Ref}
              onClick={() => {
                scrollToSection(2);
                currentSection.current = 2;
                setSection3CurrentIndex(1);
                scrollFn();
              }}
            >
              Outdoor Amenities
            </span>
            <span
              className="menu-item"
              ref={menuItem4Ref}
              onClick={() => {
                scrollToSection(2);
                currentSection.current = 2;
                setSection3CurrentIndex(2);
                scrollFn();
              }}
            >
              Indoor Amenities
            </span>
            <span
              className="menu-item"
              ref={menuItem5Ref}
              onClick={() => {
                scrollToSection(3);
                currentSection.current = 3;
                scrollFn();
              }}
            >
              Gallery
            </span>
            <span
              className="menu-item"
              ref={menuItem6Ref}
              onClick={() => {
                scrollToSection(4);
                currentSection.current = 4;
                scrollFn();
              }}
            >
              Project Status
            </span>
            <span
              className="menu-item"
              ref={menuItem7Ref}
              onClick={() => {
                scrollToSection(5);
                currentSection.current = 5;
                scrollFn();
              }}
            >
              Location
            </span>
          </div>
          <section
            className="section section-1-container fade-in-section"
            id="section-0"
          >
            <div className="overlay" />
            <img alt="" src={section1Bg} className="bg-img" />
            <div className="layer-1">
              <div className="box box-1"></div>
              <div className="box box-2"></div>
              <div className="box box-3"></div>
              <div className="box box-4"></div>

              <div className="divider-container">
                <div className="divider divider-1"></div>
                <div className="divider divider-2"></div>
                <div className="divider divider-3"></div>
              </div>
            </div>
            <div className="layer-2">
              {/* <img alt="" src={section1Asset2} className="asset-2" /> */}
              <img alt="" src={section1Asset3} className="asset-3" />
            </div>
          </section>

          <section
            style={{ height: deviceHeight, minHeight: deviceHeight }}
            className="section section-2-container fade-in-section"
            id="section-1"
          >
            {tabletMode ? projectSynopsisWeb : projectSynopsisMobile}
          </section>

          <section
            className="section section-3-container fade-in-section"
            id="section-2"
          >
            <div className="layer-1">
              <div className="layer-1-box" ref={section3BoxRef}>
                <div
                  className={`box mid-box`}
                  style={{ opacity: section3CurrentIndex === 0 ? 1 : 0 }}
                >
                  <div className="box-heading">
                    <img alt="" src={miniIcon} className="mini-icon" />
                    PROJECT SPECIFICATIONS
                  </div>
                  <div className="box-container">
                    <span>Total Project Saleable Area 1.2 Million Sq.ft.</span>
                    <span>Adequate Parking</span>
                    <span>Platinum Rating LEED Certiﬁcation</span>
                    <span>100% DG Back up</span>
                    <span>EV Charging Provision</span>
                    <span>Integrated BMS System</span>
                    <span>
                      24 Hour Security with CCTV Surveillance, Turnstile &
                      Access Control
                    </span>
                    <span>Efficient Waste Management System</span>
                    <span>Driver's Waiting room & restrooms</span>
                    <span>Collaborative spaces</span>
                    <span>Multiple entry exit points</span>
                  </div>
                </div>
                <div
                  className={`box left-box`}
                  style={{ opacity: section3CurrentIndex === 1 ? 1 : 0 }}
                >
                  <div className="box-heading">
                    <img alt="" src={miniIcon} className="mini-icon" />
                    OUTDOOR AMENITIES
                  </div>
                  <div className="box-container">
                    <span>Arrival Plaza</span>
                    <span>Outdoor Gym</span>
                    <span>Outdoor Plug and play</span>
                    <span>Amphitheatre</span>
                    <span>Outdoor Seating</span>
                    <span>Outdoor Work Points - Huddle Spaces</span>
                    <span>Walking Track</span>
                    <span>Breakout Areas</span>
                    <span>Breakout Zones</span>
                    <span>Water bodies</span>
                  </div>
                </div>
                <div
                  className={`box right-box`}
                  style={{ opacity: section3CurrentIndex === 2 ? 1 : 0 }}
                >
                  <div className="box-heading">
                    <img alt="" src={miniIcon} className="mini-icon" />
                    INDOOR AMENITIES
                  </div>
                  <div className="box-container">
                    <span>Coffee Bar</span>
                    <span>Auditorium With Pre Function Area</span>
                    <span>Indoor Cafeteria</span>
                    <span>Creche With Outdoor Play Area</span>
                    <span>Fitness Centre</span>
                    <span>Business Lounge</span>
                    <span>Meeting Rooms</span>
                    <span>Huddle Spaces</span>
                    <span>Retail - Car Showroom</span>
                    <span>Collaborative/Huddle spaces</span>
                    <span>Mail/Parcel room</span>
                    <span>Medical room</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section section-4-container">
            <img
              alt=""
              src={section4Bg}
              ref={section4BgRef}
              className={`bg-img section-4-bg ${showSection4Bg ? "" : ""}`}
              style={{ opacity: showSection4Bg ? 1 : 0 }}
            />
            <div
              ref={section4Ref1}
              className={`layer-1 fade-in-section`}
              id="section-3"
            >
              <div className="layer-1-container">
                <div className="heading">
                  <img alt="" src={miniIcon} className="mini-icon" />
                  GALLERY
                </div>
                <div className="tab-menu">
                  <span
                    className={`${
                      section4SelectedTabIndex === 0 ? "selected" : ""
                    }`}
                    onClick={() => setSection4SelectedTabIndex(0)}
                  >
                    Exterior
                  </span>
                  <span
                    className={`${
                      section4SelectedTabIndex === 1 ? "selected" : ""
                    }`}
                    onClick={() => setSection4SelectedTabIndex(1)}
                  >
                    Interior
                  </span>
                </div>

                {section4SelectedTabIndex === 0 ? (
                  <div className="main-content">
                    <div
                      ref={carousel1Ref1}
                      className={`image-row row-1`}
                      onScroll={(e) => e.stopPropagation()}
                    >
                      <div
                        className={`image-container ${
                          selected4Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={extImg1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected4Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={extImg2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected4Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={extImg3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected4Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={extImg4} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="main-content">
                    <div
                      ref={carousel1Ref2}
                      className={`image-row row-1`}
                      onScroll={(e) => e.stopPropagation()}
                    >
                      <div
                        className={`image-container ${
                          selected4Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={intImg1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected4Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={intImg2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected4Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={intImg3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected4Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={intImg4} />
                      </div>
                      <div
                        className={`image-container ${
                          selected4Image === 5 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected4Image(5);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={intImg5} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              ref={section5Ref1}
              className={`layer-1 fade-in-section`}
              id="section-4"
            >
              <div className="layer-2-container">
                <div className="heading">
                  <img alt="" src={miniIcon} className="mini-icon" />
                  PROJECT STATUS
                </div>
                <div className="tab-menu">
                  <span
                    className={`${
                      section5SelectedTabIndex === 8 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(8)}
                  >
                    January Video 2025
                  </span>
                  <span
                    className={`${
                      section5SelectedTabIndex === 7 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(7)}
                  >
                    January 2025
                  </span>
                  <span
                    className={`${
                      section5SelectedTabIndex === 6 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(6)}
                  >
                    December 2024
                  </span>
                  <span
                    className={`${
                      section5SelectedTabIndex === 5 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(5)}
                  >
                    November 2024
                  </span>
                  <span
                    className={`${
                      section5SelectedTabIndex === 4 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(4)}
                  >
                    October 2024
                  </span>
                  <span
                    className={`${
                      section5SelectedTabIndex === 3 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(3)}
                  >
                    September 2024
                  </span>
                  <span
                    className={`${
                      section5SelectedTabIndex === 2 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(2)}
                  >
                    August 2024
                  </span>
                  <span
                    className={`${
                      section5SelectedTabIndex === 1 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(1)}
                  >
                    July 2024
                  </span>
                  <span
                    className={`${
                      section5SelectedTabIndex === 0 ? "selected" : ""
                    }`}
                    onClick={() => setSection5SelectedTabIndex(0)}
                  >
                    June 2024
                  </span>
                </div>

                {section5SelectedTabIndex === 8 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img
                          alt=""
                          className="gallery-thumb"
                          src={january2024Thumbnail}
                        />
                      </div>
                    </div>
                  </div>
                ) : section5SelectedTabIndex === 7 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={january2024Img1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={january2024Img2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={january2024Img3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={january2024Img4} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={january2024Img5} />
                      </div>
                    </div>
                  </div>
                ) : section5SelectedTabIndex === 6 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={december2024Img1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={december2024Img2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={december2024Img3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={december2024Img4} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={december2024Img5} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={december2024Img6} />
                      </div>
                    </div>
                  </div>
                ) : section5SelectedTabIndex === 5 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={november2024Img1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={november2024Img2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={november2024Img3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={november2024Img4} />
                      </div>
                    </div>
                  </div>
                ) : section5SelectedTabIndex === 4 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={october2024Img1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={october2024Img2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={october2024Img3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={october2024Img4} />
                      </div>
                    </div>
                  </div>
                ) : section5SelectedTabIndex === 3 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={septImg1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={septImg2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={septImg3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={septImg4} />
                      </div>
                    </div>
                  </div>
                ) : section5SelectedTabIndex === 2 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={AugustImg1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={AugustImg2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={AugustImg3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={AugustImg4} />
                      </div>
                    </div>
                  </div>
                ) : section5SelectedTabIndex === 1 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={julyImg1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={julyImg2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={julyImg3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={julyImg4} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 5 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(5);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={julyImg5} />
                      </div>
                    </div>
                  </div>
                ) : section5SelectedTabIndex === 0 ? (
                  <div className="main-content">
                    <div ref={carousel2Ref1} className={`image-row row-1`}>
                      <div
                        className={`image-container ${
                          selected5Image === 1 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(1);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={juneImg1} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 2 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(2);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={juneImg2} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 3 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(3);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={juneImg3} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 4 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(4);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={juneImg4} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 5 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(5);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={juneImg5} />
                      </div>
                      <div
                        className={`image-container ${
                          selected5Image === 6 ? "zoom-img" : ""
                        }`}
                        onClick={() => {
                          setSelected5Image(6);
                          setClickedImage(true);
                        }}
                      >
                        <img alt="" src={juneImg6} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div
              className="section section-6-container fade-in-section"
              id="section-5"
            >
              <div className="layer-1" ref={section6Ref1}>
                <div className="image-container">
                  <div className="text-container">
                    <div className="heading">
                      <img src={icon1} alt="" className="mini-icon" />
                      LOCATION
                    </div>
                    {/* <img alt="" src={section5Bg} className="bg-img" /> */}
                    <div className="google-maps-iframe-container">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.357636798076!2d80.2171793756897!3d13.01288258730636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52670042213b65%3A0x242048801d3ad3d1!2sBaashyaam%20Promenade!5e0!3m2!1sen!2sin!4v1717319506721!5m2!1sen!2sin"
                        style={{ border: 0, width: "60vw", height: 200 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                      <div className="logo-container">
                        <img alt="" src={logo2} className="asset-1" />
                        <span className="address-text">
                          Anna Salai, Little Mount, Guindy, Chennai, Tamil Nadu
                          – 600032.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section section-7-container" id="section-6">
              <div className="layer-1" ref={section7Ref1}>
                <div className="image-container">
                  <img alt="" src={section6Bg} className="bg-img" />
                  <div className="btn-container">
                    <span className={`arrow-container`} {...handlers}></span>
                    <span className="text"></span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="section section-8-container fade-in-section footer-section"
              id="section-7"
            >
              <div className="layer-1" ref={section8Ref1}>
                <Footer />
              </div>
            </div>
          </section>
        </div>
      </div>

      {imageModal ? (
        <Modal
          styles={{ mask: { background: "rgba(0, 0, 0, 0.65)" } }}
          className="image-modal-mobile"
          open={true}
          onCancel={() => {
            setSelected4Image(null);
            setClickedImage(false);
            setSelected5Image(null);
            setImageModal(false);
            sliderModalRef.current = 0;
          }}
          footer={false}
        >
          {selected4Image ? (
            <div
              className={`carousel-container ${
                clickedImage ? "" : "set-animate"
              }`}
              id="carousel-container-4"
              onTouchMove={(e) => slideImageMove(e, 4)}
              onTouchStart={(e) => slideImageStart(e, 4)}
              onTouchEnd={() => (imageSwiped.current = false)}
            >
              {section4SelectedTabIndex === 0 ? (
                <>
                  <div className="carousel_item">
                    <img alt="" src={extImg1} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={extImg2} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={extImg3} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={extImg4} />
                  </div>
                </>
              ) : (
                <>
                  <div className="carousel_item">
                    <img alt="" src={intImg1} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={intImg2} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={intImg3} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={intImg4} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={intImg5} />
                  </div>
                </>
              )}
              <div className="pagination">
                <span>{selected4Image}</span>/<span>{total4Image}</span>
              </div>
              {/* {showLeft4Arrow ? <LeftOutlined onClick={() => slideLeft(4)} /> : <></>}
            {showRight4Arrow ? <RightOutlined onClick={() => slideRight(4)} /> : <></>} */}
            </div>
          ) : (
            <></>
          )}

          {selected5Image ? (
            section5SelectedTabIndex === 8 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={january2024Img1} />
                </div>
                <div className="carousel_item">
                  <iframe
                    title="video"
                    src={"https://www.youtube.com/embed/qzDVkcAnm6g?autoplay=1"}
                    // frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    style={{ width: "100%", height: "50vh" }}
                  />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : section5SelectedTabIndex === 7 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={january2024Img1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={january2024Img2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={january2024Img3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={january2024Img4} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={january2024Img5} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : section5SelectedTabIndex === 6 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={december2024Img1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={december2024Img2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={december2024Img3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={december2024Img4} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={december2024Img5} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={december2024Img6} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : section5SelectedTabIndex === 5 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={november2024Img1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={november2024Img2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={november2024Img3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={november2024Img4} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : section5SelectedTabIndex === 4 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={october2024Img1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={october2024Img2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={october2024Img3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={october2024Img4} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : section5SelectedTabIndex === 3 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={septImg1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={septImg2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={septImg3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={septImg4} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : section5SelectedTabIndex === 2 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={AugustImg1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={AugustImg2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={AugustImg3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={AugustImg4} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : section5SelectedTabIndex === 1 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={julyImg1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={julyImg2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={julyImg3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={julyImg4} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={julyImg5} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : section5SelectedTabIndex === 0 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
                onTouchMove={(e) => slideImageMove(e, 5)}
                onTouchStart={(e) => slideImageStart(e, 5)}
                onTouchEnd={() => (imageSwiped.current = false)}
              >
                <div className="carousel_item">
                  <img alt="" src={juneImg1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg4} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg5} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg6} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {/* {showLeft5Arrow ? <LeftOutlined onClick={() => slideLeft(5)} /> : <></>}
            {showRight5Arrow ? <RightOutlined onClick={() => slideRight(5)} /> : <></>} */}
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Modal>
      ) : (
        ""
      )}

      <Modal
        centered
        className="user-form-modal-mobile"
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          clearFormData();
        }}
        footer={false}
      >
        <div className="heading">Please fill the form</div>
        <Form layout={formLayout} form={form}>
          <Form.Item label="Name">
            <span
              className="error-text-form"
              style={{
                left: "55px",
                display: showRequiredError[0] ? "block" : "none",
              }}
            >
              Required
            </span>
            <Input
              placeholder="Enter Name"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              className={showRequiredError[0] ? "has-error" : ""}
            />
          </Form.Item>
          <Form.Item label="Contact Number">
            <span
              className="error-text-form"
              style={{
                left: "112px",
                display: showRequiredError[1] ? "block" : "none",
              }}
            >
              Required
            </span>
            <Input
              placeholder="Enter Contact Number"
              value={formContact}
              onChange={(e) => setFormContact(e.target.value)}
              className={showRequiredError[1] ? "has-error" : ""}
            />
          </Form.Item>
          <Form.Item label="Email">
            <span
              className="error-text-form"
              style={{
                left: "55px",
                display: showRequiredError[2] ? "block" : "none",
              }}
            >
              Required
            </span>
            <Input
              placeholder="Enter Email"
              value={formEmail}
              onChange={(e) => setFormEmail(e.target.value)}
              className={showRequiredError[2] ? "has-error" : ""}
            />
          </Form.Item>
          <Form.Item className="text-area" label="Message">
            <span
              className="error-text-form"
              style={{
                left: "72px",
                display: showRequiredError[3] ? "block" : "none",
              }}
            >
              Required
            </span>
            <TextArea
              rows={4}
              placeholder="Message......."
              value={formMessage}
              onChange={(e) => setFormMessage(e.target.value)}
              className={showRequiredError[3] ? "has-error" : ""}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                submitFormData();
              }}
            >
              Submit
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowModal(false);
                clearFormData();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        className="user-form-success-modal-mobile"
        open={showSuccessModal}
        closable={false}
        footer={false}
      >
        <div>Thank you for enquiring about our prestigious project. </div>
        <div>Our Product Specialist will contact you shortly.</div>
      </Modal>
    </>
  );
}

export default PromenadeMobile;
