import React, { useEffect, useState } from "react";
import Header from "./Header";
import "../../css/bootstrap.css";
import "../../css/common.css";
import "../../css/modal.css";
import Footer from "./Footer";
import favicon from "../../images/favicon.png";
import Helmet from "react-helmet";

function Layout({ children, chatCodeId, from = "" }) {
  const [windowObject, setWindowObject] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowObject(window);
    }
  }, []);

  // useEffect(() => {
  //   if (!document.getElementById("lp_cwc_xqzyihjdskw")) {
  //     let chatDiv = document.getElementById("livprop_chatbox");
  //     if (chatDiv) {
  //       document.body.removeChild(chatDiv);
  //       if (
  //         chatDiv.nextElementSibling &&
  //         chatDiv.nextElementSibling.tagName === "script"
  //       ) {
  //         document.body.removeChild(chatDiv.nextElementSibling);
  //       }
  //     }
  //     const s1 = document.createElement("script");
  //     s1.id = "lp_cwc_xqzyihjdskw";
  //     s1.src = `https://cwc.livserv.in/chat.js?lid=${
  //       chatCodeId ? chatCodeId : 12981
  //     }`;
  //     const s2 = document.createElement("script");
  //     s2.id = "chatScript2";
  //     s2.src = `https://cw1.livserv.in?did=${
  //       chatCodeId ? chatCodeId : 12981
  //     }&pid=1`;
  //     document.body.appendChild(s1);
  //     document.body.appendChild(s2);

  //     return () => {
  //       document.body.removeChild(s1);
  //       document.body.removeChild(s2);
  //     };
  //   }
  // }, [chatCodeId]);

  // useEffect(() => {
  //   const whatsappbutton = document.querySelector('#whatsappbutton')
  //   const mobilelivesquare = document.querySelector('.mobilelivesquare')

  //   if(whatsappbutton && mobilelivesquare){
  //     console.log(windowObject?.location?.pathname.includes("promenade"))
  //     if(!windowObject?.location?.pathname.includes("promenade")) {
  //     whatsappbutton.style.display = 'block'
  //     mobilelivesquare.style.display = 'block'
  //     }
  //    else {
  //     whatsappbutton.style.display = 'none'
  //     mobilelivesquare.style.display = 'none'
  //   }
  // }
  // },[windowObject?.location?.pathname])

  return (
    <>
      <Header />
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>Baashyaam Group - Luxury Apartments in Chennai</title>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-DT3HD7V0M9"
        ></script>
      </Helmet>
      <main className="page-container">{children}</main>
      {from !== "Promenade" ? <Footer /> : ""}
    </>
  );
}

export default Layout;
